import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectCta from "../components/projectCta"
import SimpleHeader from "../components/simpleHeader"
import { graphql, Link } from "gatsby"
import ReviewOverview from "../components/reviewOverview"

const ProjectsPage = ({
  data: {
    contentfulTestimonials: { title, introduction, projects, contactCta },
  },
}) => (
  <Layout>
    <Seo title="Testimonials" />

    <SimpleHeader
      smallTitle="Our Testimonials"
      title={title}
      description={introduction}
    />

    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 divide-y divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900">
          What our customers have said
        </h2>
        <div className="mt-8">
          <dl className="divide-y divide-gray-200">
            {projects.map(
              ({
                id,
                slug,
                customerName,
                address,
                testimonial: { testimonial },
              }) => (
                <div
                  key={id}
                  className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8"
                >
                  <dt className="text-base font-medium text-gray-700 md:col-span-4">
                    <Link to={`/projects/${slug}`}>
                      {customerName} — {address}
                    </Link>
                  </dt>
                  <dd className="mt-2 md:mt-0 md:col-span-8">
                    <Link to={`/projects/${slug}`}>
                      <p className="text-lg text-gray-500">
                        <span className="text-4xl text-teal-500">“</span>
                        {testimonial}
                      </p>
                    </Link>
                  </dd>
                </div>
              )
            )}
          </dl>
        </div>
      </div>
    </div>

    <ReviewOverview />

    <ProjectCta text={contactCta} />
  </Layout>
)

export const query = graphql`
  {
    contentfulTestimonials {
      title
      introduction
      contactCta
      projects {
        id
        testimonial {
          testimonial
        }
        customerName
        address
        slug
      }
    }
  }
`

export default ProjectsPage
